<template>
<div>
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/home" />
      <q-breadcrumbs-el label="Jobs" to="/jobs/list/1" />
      <q-breadcrumbs-el label="States" />
    </q-breadcrumbs>

  <div class="text-h5 q-mt-md">Jobs by State - Totals</div>

  <div class="row">
    <div v-for="state in stateTotals" :key="state._id" class="col-6 col-sm-4 q-pa-sm">
      <router-link :to="{ name: 'jobStateList', params: { stateCode: state._id, pageId: 1 }}" class="text-primary text-bold">{{state.name}}</router-link>: {{state.count}}
    </div>
  </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import jobModule from "../../../store";

export default {
  data() {
    return {
    }
  },
  computed: {
      ...mapState('job', {
        loading: 'loading',
      }),
      // ...mapState('site', {
      //   siteArrays: 'arrs',
      // }),

      stateTotals() {
        return this.$store.getters['job/stateTotalsAlpha'] || [];
      },
  },

  created () {
    this.reqData();
  },

  methods: {
    reqData (force) {
      console.log('reqData totals');
      if (! this.stateTotals.length || force) {
        console.log('dispatching... req totals');
        this.$store.dispatch( 'job/' + jobModule.actionTypes.LoadTotalsRequest );
      }
    },
  },
}
</script>



